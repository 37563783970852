<script lang="ts" setup>
import { ref } from "vue";

const dayjs = useDayjs();

interface Props {
  modelValue: string;
  yearsReversed?: boolean;
  autocorrect?: boolean;
  minDate: string;
  minDateErrorMessage?: string;
  maxDate: string;
  maxDateErrorMessage?: string;
}
const props = defineProps<Props>();

interface Emits {
  (e: "update:modelValue", value: string): void;
}
const emit = defineEmits<Emits>();

const month = ref<number>(-1); // 0 to 11
const day = ref<number>(-1); // 1 to 31
const year = ref<number>(-1);

const dayValid = computed(() => {
  if (month.value >= 0 && year.value >= 0 && day.value >= 0) {
    return dayjs().set("month", month.value).set("year", year.value).daysInMonth() >= day.value;
  }
});

const date = computed(() => {
  if (month.value >= 0 && year.value >= 0 && day.value >= 0) {
    return dayjs().set("month", month.value).set("year", year.value).set("date", day.value).format("YYYY-MM-DD");
  }
});

const dateBeforeMinDate = computed(() => date.value && dayjs(props.minDate).diff(date.value, "day") > 0);
const dateAfterMaxDate = computed(() => date.value && dayjs(props.maxDate).diff(date.value, "day") < 0);

function updateInputs() {
  if (props.modelValue) {
    const [y, m, d] = props.modelValue.split("-");
    year.value = parseInt(y);
    month.value = parseInt(m) - 1;
    day.value = parseInt(d);
  }
}

const order = computed(() => {
  const parts = dayjs()
    .localeData()
    .longDateFormat("L")
    .split(/[^A-Z]/);
  return {
    day: parts.indexOf("DD"),
    month: parts.indexOf("MM"),
    year: parts.indexOf("YYYY"),
  };
});

function updateModelValue() {
  if (!(date.value && dayValid.value)) {
    return emit("update:modelValue", "");
  }

  if (props.autocorrect) {
    if (dateBeforeMinDate.value) {
      emit("update:modelValue", props.minDate);
      updateInputs();
      return;
    } else if (dateAfterMaxDate.value) {
      emit("update:modelValue", props.maxDate);
      updateInputs();
      return;
    }
  } else if (dateBeforeMinDate.value || dateAfterMaxDate.value) {
    return emit("update:modelValue", "");
  }

  return emit("update:modelValue", date.value);
}

const monthOptions = dayjs.months();
const dateOptions = Array.from({ length: 31 }, (_, i) => i + 1);
const yearOptions = computed(() => {
  const years = Array.from(
    { length: parseInt(props.maxDate.split("-")[0]) - parseInt(props.minDate.split("-")[0]) + 1 },
    (_, i) => i + parseInt(props.minDate.split("-")[0]),
  );

  if (props.yearsReversed) {
    return years.reverse();
  }

  return years;
});

watch(() => props.modelValue, updateInputs);
onMounted(updateInputs);
</script>

<template>
  <div>
    <div class="flex w-full grid-cols-12 flex-row gap-3">
      <div
        class="basis-1/2"
        :class="{ 'order-1': order.month === 0, 'order-2': order.month === 1, 'order-3': order.month === 2 }"
      >
        <label class="text-sm text-neutral-600" for="month">
          {{ $t("registration.birthday.month") }}
        </label>
        <select
          id="month"
          v-model="month"
          class="block w-full appearance-none rounded-lg border-2 border-neutral-200 p-2 text-xl text-neutral-800 focus-visible:border-delight-blue-300 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-delight-blue-300"
          @change="updateModelValue"
        >
          <option value="-1" disabled />
          <option v-for="(monthLabel, monthValue) in monthOptions" :key="monthValue" :value="monthValue">
            {{ monthLabel }}
          </option>
        </select>
      </div>

      <div
        class="basis-1/4"
        :class="{ 'order-1': order.day === 0, 'order-2': order.day === 1, 'order-3': order.day === 2 }"
      >
        <label class="text-sm text-neutral-600" for="day">
          {{ $t("registration.birthday.day") }}
        </label>
        <select
          id="day"
          v-model="day"
          class="block w-full appearance-none rounded-lg border-2 border-neutral-200 p-2 text-xl text-neutral-800 focus-visible:border-delight-blue-300 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-delight-blue-300"
          :class="{
            'border-yz-red-danger ring-delight-red-600 focus-visible:border-delight-red-600 focus-visible:ring-delight-red-600':
              dayValid === false,
          }"
          @change="updateModelValue"
        >
          <option value="-1" disabled />
          <option v-for="(dayLabel, dayIndex) in dateOptions" :key="'day-' + dayIndex" :value="dayLabel">
            {{ dayLabel }}
          </option>
        </select>
      </div>
      <div
        class="basis-1/4"
        :class="{ 'order-1': order.year === 0, 'order-2': order.year === 1, 'order-3': order.year === 2 }"
      >
        <label class="text-sm text-neutral-600" for="year">
          {{ $t("registration.birthday.year") }}
        </label>
        <select
          id="year"
          v-model="year"
          class="block w-full appearance-none rounded-lg border-2 border-neutral-200 p-2 text-xl text-neutral-800 focus-visible:border-delight-blue-300 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-delight-blue-300"
          @change="updateModelValue"
        >
          <option value="-1" disabled />
          <option v-for="(yearLabel, yearIndex) in yearOptions" :key="'year-' + yearIndex" :value="yearLabel">
            {{ yearLabel }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="dateBeforeMinDate && minDateErrorMessage" class="mt-1 flex text-sm text-yz-red-danger">
      {{ minDateErrorMessage }}
    </div>
    <div v-if="dateAfterMaxDate && maxDateErrorMessage" class="flex text-yz-red-danger">
      {{ maxDateErrorMessage }}
    </div>
  </div>
</template>
