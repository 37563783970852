import { ref } from "vue";
import type { UnitMass } from "~/types/onboarding/registrationParams";

export function useWeight(initialWeight: number | null, initialUnitMass: UnitMass, isTargetWeight?: boolean) {
  const { kgToPounds, poundsToKg } = useUnitsConverter();
  const { t } = useNuxtApp().$i18n;

  const minKg = 35;
  const maxKg = 700;
  const kgAsString = ref<string | null>(initialWeight ? initialWeight.toString() : null);
  const kg = computed(() => (kgAsString.value ? parseFloat(kgAsString.value) : null));

  const minLb = 77.2;
  const maxLb = 1543.2;
  const lbAsString = ref<string | null>(kg.value ? kgToPounds(kg.value).toString() : null);
  const lb = computed(() => (lbAsString.value ? parseFloat(lbAsString.value) : null));

  const unitMass = ref<UnitMass>(initialUnitMass);

  function toFloatStringWithMaximumOneDigit(val: number) {
    return parseFloat(val.toFixed(1)).toString();
  }

  watch(unitMass, () => {
    if (unitMass.value === "kg") {
      kgAsString.value = lb.value ? toFloatStringWithMaximumOneDigit(poundsToKg(lb.value)) : null;
    } else {
      lbAsString.value = kg.value ? toFloatStringWithMaximumOneDigit(kgToPounds(kg.value)) : null;
    }
  });

  const canContinue = computed(() => {
    return (
      (unitMass.value === "kg" && kgAsString.value?.length !== 0) ||
      (unitMass.value === "lb" && lbAsString.value?.length !== 0)
    );
  });

  function hasMoreThanOneDecimal(val: string | null) {
    return val && val?.split(".")?.[1]?.length > 1;
  }

  const kgInvalid = ref(false);
  const lbInvalid = ref(false);
  const error = ref<string>("");

  function validate() {
    kgInvalid.value = false;
    lbInvalid.value = false;
    error.value = "";

    if (unitMass.value === "kg") {
      if (!kg.value || !kgAsString.value || hasMoreThanOneDecimal(kgAsString.value)) {
        kgInvalid.value = true;
      }

      if (kg.value) {
        if (kg.value < minKg) {
          error.value = isTargetWeight
            ? t("registration.safety_check.goal_weight.too_low_short")
            : t("registration.safety_check.starting_weight.too_low", [`${minKg}${t("registration.unit.kg")}`]);
          kgInvalid.value = true;
          lbInvalid.value = true;
        } else if (kg.value > maxKg) {
          error.value = isTargetWeight
            ? t("registration.safety_check.goal_weight.too_high_short")
            : t("registration.safety_check.starting_weight.too_high", [`${maxKg}${t("registration.unit.kg")}`]);
          kgInvalid.value = true;
          lbInvalid.value = true;
        }
      }
    } else if (unitMass.value === "lb") {
      if (!lb.value || !lbAsString.value || hasMoreThanOneDecimal(lbAsString.value)) {
        lbInvalid.value = true;
      }

      if (lb.value) {
        kgAsString.value = toFloatStringWithMaximumOneDigit(poundsToKg(lb.value));

        if (lb.value < minLb) {
          error.value = isTargetWeight
            ? t("registration.safety_check.goal_weight.too_low_short")
            : t("registration.safety_check.starting_weight.too_low", [`${minLb}${t("registration.unit.lb")}`]);
          lbInvalid.value = true;
          kgInvalid.value = true;
        } else if (lb.value > maxLb) {
          error.value = isTargetWeight
            ? t("registration.safety_check.goal_weight.too_high_short")
            : t("registration.safety_check.starting_weight.too_high", [`${maxLb}${t("registration.unit.lb")}`]);
          lbInvalid.value = true;
          kgInvalid.value = true;
        }
      }
    }
    return kgInvalid.value || lbInvalid.value || error.value;
  }

  return { kg, kgAsString, lbAsString, unitMass, validate, kgInvalid, lbInvalid, error, canContinue };
}
