<script lang="ts" setup>
import { ref } from "vue";
import type { UnitLength } from "~/types/onboarding/registrationParams";

interface Props {
  cm: string | null;
  feet: string | null;
  inch: string | null;
  unitLength: UnitLength;
  cmInvalid?: boolean;
  ftInvalid?: boolean;
  inInvalid?: boolean;
  errorMessage?: string;
  disableAutoFocus?: boolean;
}

const props = defineProps<Props>();

interface Emits {
  (e: "update:cm", value: string | null): void;
  (e: "update:feet", value: string | null): void;
  (e: "update:inch", value: string | null): void;
  (e: "update:unitLength", value: UnitLength): void;
  (e: "submit"): void;
  (e: "blur"): void;
}

const emit = defineEmits<Emits>();

function isUnitSelected(unit: UnitLength) {
  return props.unitLength === unit;
}

const cmInput = ref<HTMLInputElement>();
const feetInput = ref<HTMLInputElement>();

function setFocus() {
  void nextTick(() => {
    if (props.unitLength === "cm") {
      if (cmInput.value) {
        cmInput.value.focus();
      }
    } else if (props.unitLength === "in") {
      if (feetInput.value) {
        feetInput.value.focus();
      }
    }
  });
}

onMounted(() => {
  if (!props.disableAutoFocus) {
    setFocus();
  }
});
watch(() => props.unitLength, setFocus);

function submitOnEnter(event: KeyboardEvent) {
  if (event.key === "Enter") {
    emit("submit");
  }
}
</script>

<template>
  <div>
    <div class="flex gap-3">
      <div v-if="isUnitSelected('cm')" class="flex w-full justify-center text-neutral-600">
        <input
          id="heightInput"
          ref="cmInput"
          inputmode="numeric"
          :value="cm"
          class="min-w-0 rounded-l-lg border-2 p-4 text-xl focus-visible:ring-1"
          :class="[
            cmInvalid
              ? 'border-yz-red-danger ring-yz-red-danger focus-visible:border-yz-red-danger focus-visible:ring-yz-red-danger'
              : 'border-neutral-300 focus-visible:border-delight-blue-300 focus-visible:outline-none focus-visible:ring-delight-blue-300',
          ]"
          type="number"
          :autofocus="!disableAutoFocus"
          @input="$emit('update:cm', ($event.target as HTMLInputElement).value)"
          @keydown="submitOnEnter"
          @blur="emit('blur')"
        />
        <div
          class="-z-10 flex aspect-square items-center rounded-r-lg border-2 border-l-0 border-neutral-300 bg-neutral-100 p-4 align-middle"
        >
          {{ $t("registration.unit.cm") }}
        </div>
      </div>

      <div v-else class="flex w-full justify-center gap-3">
        <div class="flex w-1/2 text-neutral-600 sm:w-1/3">
          <input
            ref="feetInput"
            inputmode="numeric"
            :autofocus="!disableAutoFocus"
            :value="feet"
            class="min-w-0 rounded-l-lg border-2 p-4 text-xl focus-visible:ring-1"
            :class="[
              ftInvalid
                ? 'border-yz-red-danger ring-yz-red-danger focus-visible:border-yz-red-danger focus-visible:ring-yz-red-danger'
                : 'border-neutral-300 focus-visible:border-delight-blue-300 focus-visible:outline-none focus-visible:ring-delight-blue-300',
            ]"
            type="number"
            @input="$emit('update:feet', ($event.target as HTMLInputElement).value)"
            @keydown="submitOnEnter"
            @blur="emit('blur')"
          />
          <div
            class="-z-10 flex aspect-square items-center rounded-r-lg border-2 border-l-0 border-neutral-300 bg-neutral-100 p-4 align-middle"
          >
            {{ $t("registration.unit.ft") }}
          </div>
        </div>
        <div class="flex w-1/2 text-neutral-600 sm:w-1/3">
          <input
            :value="inch"
            inputmode="numeric"
            class="min-w-0 rounded-l-lg border-2 p-4 text-xl focus-visible:ring-1"
            :class="[
              inInvalid
                ? 'border-yz-red-danger ring-yz-red-danger focus-visible:border-yz-red-danger focus-visible:ring-yz-red-danger'
                : 'border-neutral-300 focus-visible:border-delight-blue-300 focus-visible:outline-none focus-visible:ring-delight-blue-300',
            ]"
            type="number"
            @input="$emit('update:inch', ($event.target as HTMLInputElement).value)"
            @keydown="submitOnEnter"
            @blur="emit('blur')"
          />
          <div
            class="-z-10 flex aspect-square items-center rounded-r-lg border-2 border-l-0 border-neutral-300 bg-neutral-100 p-4 align-middle"
          >
            {{ $t("registration.unit.in") }}
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 flex justify-center gap-3">
      <button
        id="unitCm"
        class="rounded-full border-2 bg-white px-5 py-2.5 shadow"
        :class="[isUnitSelected('cm') ? 'border-delight-blue-300' : 'border-transparent']"
        @click="emit('update:unitLength', 'cm')"
      >
        {{ $t("registration.unit.cm") }}
      </button>
      <button
        id="unitFtIn"
        class="rounded-full border-2 bg-white px-5 py-2.5 shadow"
        :class="[isUnitSelected('in') ? 'border-delight-blue-300' : 'border-transparent']"
        @click="emit('update:unitLength', 'in')"
      >
        {{ $t("registration.unit.ft_in") }}
      </button>
    </div>
    <div class="mt-3">
      <div class="text-yz-red-danger w-full text-center">&nbsp;{{ errorMessage }}</div>
    </div>
  </div>
</template>
