import { ref } from "vue";
import type { UnitLength } from "~/types/onboarding/registrationParams";

export function useHeight(initialHeight: number | null, initialUnitLength: UnitLength) {
  const { defaultUnitLength, t } = useNuxtApp().$i18n;
  const { cmToFeetAndInches, feetAndInchesToCm } = useUnitsConverter();

  const minCm = 50;
  const maxCm = 300;
  const cmAsString = ref<string | null>(initialHeight ? initialHeight.toString() : null);
  const cm = computed(() => (cmAsString.value ? parseFloat(cmAsString.value) : null));
  const minFeet = 1;
  const maxFeet = 9;
  const minInches = 0;
  const maxInches = 11;
  const minTotalInch = 20; // 1ft 8 inch
  const maxTotalInch = 118; // 9ft 10inch
  const feetAsString = ref<string | null>(initialHeight ? initialHeight.toString() : null);
  const feet = computed(() => (feetAsString.value ? parseFloat(feetAsString.value) : null));
  const inchAsString = ref<string | null>(initialHeight ? initialHeight.toString() : null);
  const inch = computed(() => (inchAsString.value ? parseFloat(inchAsString.value) : null));

  const unitLength = ref<UnitLength>(initialUnitLength || defaultUnitLength.value);

  if (cm.value) {
    const { feet: _feet, inches } = cmToFeetAndInches(cm.value);
    feetAsString.value = _feet.toString();
    inchAsString.value = inches.toString();
  }

  watch(unitLength, () => {
    if (unitLength.value === "cm" && feet.value) {
      cmAsString.value = feetAndInchesToCm(feet.value ?? 0, inch.value ?? 0).toString();
    } else if (cmAsString.value && cm.value) {
      const { feet: _feet, inches } = cmToFeetAndInches(parseFloat(cmAsString.value));
      feetAsString.value = _feet.toString();
      inchAsString.value = inches.toString();
    }
  });

  const totalInch = computed(() => (feet.value ? feet.value * 12 : 0) + (inch.value ? inch.value : 0));

  const canContinue = computed(() => {
    return (
      (unitLength.value === "cm" && cmAsString.value?.length !== 0) ||
      (unitLength.value === "in" && (feetAsString.value?.length !== 0 || inchAsString.value?.length !== 0))
    );
  });

  const cmInvalid = ref(false);
  const feetInvalid = ref(false);
  const inchInvalid = ref(false);
  const error = ref<string>("");

  function hasDecimals(val: number | null) {
    return val && val % 1 > 0;
  }

  function isOutsideRange(val: number | null, min: number, max: number) {
    if (val === 0) {
      return true;
    }

    return val && (val < min || val > max);
  }

  function validate() {
    cmInvalid.value = false;
    feetInvalid.value = false;
    inchInvalid.value = false;
    error.value = "";

    if (unitLength.value === "in") {
      cmAsString.value = feetAndInchesToCm(feet.value ?? 0, inch.value ?? 0).toString();

      if (
        !feet.value ||
        !feetAsString.value ||
        isOutsideRange(feet.value, minFeet, maxFeet) ||
        hasDecimals(feet.value)
      ) {
        feetInvalid.value = true;
      }

      if (
        (!feetAsString.value && !inchAsString.value) ||
        (!feet.value && !inch.value) ||
        isOutsideRange(inch.value, minInches, maxInches) ||
        hasDecimals(inch.value)
      ) {
        inchInvalid.value = true;
      }

      if (isOutsideRange(totalInch.value, minTotalInch, maxTotalInch)) {
        error.value = t("registration.safety_check.height.range", [
          `1${t("registration.unit.ft")} 8${t("registration.unit.in")}`,
          `9${t("registration.unit.ft")} 10${t("registration.unit.in")}`,
        ]);
        feetInvalid.value = true;
        inchInvalid.value = true;
        cmInvalid.value = true;
      }
    } else if (unitLength.value === "cm") {
      if (!cm.value || (cm.value && cm.value % 1 > 0)) {
        cmInvalid.value = true;
      }

      if (isOutsideRange(cm.value, minCm, maxCm)) {
        error.value = t("registration.safety_check.height.range", [
          `50${t("registration.unit.cm")}`,
          `300${t("registration.unit.cm")}`,
        ]);
        cmInvalid.value = true;
        feetInvalid.value = true;
        inchInvalid.value = true;
      }
    }

    return cmInvalid.value || feetInvalid.value || inchInvalid.value || error.value;
  }

  return {
    cm,
    cmAsString,
    feetAsString,
    inchAsString,
    unitLength,
    validate,
    cmInvalid,
    feetInvalid,
    inchInvalid,
    error,
    canContinue,
  };
}
