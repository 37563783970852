<script lang="ts" setup>
import { useMounted } from "@vueuse/core";
import AppIcon from "~/components/app/AppIcon.vue";

interface Props {
  emoji?: string;
  icon?: string;
  image?: string;
  translationKey: string;
  descriptionTranslationKey?: string;
  isColumn?: boolean;
  fullWidth?: boolean;
  centerText?: boolean;
  statusText?: string;
  selected?: boolean;
}

withDefaults(defineProps<Props>(), {
  fullWidth: true,
  centerText: false,
  statusText: "",
  emoji: "",
  icon: "",
  image: "",
  descriptionTranslationKey: "",
  selected: false,
});

const isMounted = useMounted();
</script>

<template>
  <button
    class="relative flex items-center rounded-xl border-2 border-solid bg-white px-5 py-4 text-left font-normal shadow-btn transition-colors hover:bg-yz-gray-50"
    :class="[
      {
        '!flex-col': isColumn,
        'animate-pulse cursor-wait': !isMounted,
        '!w-full': fullWidth,
        'card-image': image,
        'gap-2': !image,
        '!pt-10': statusText,
      },
      selected ? 'border-delight-blue-300' : 'border-transparent',
    ]"
    :disabled="!isMounted"
  >
    <div
      v-if="statusText"
      class="absolute inset-x-0 top-0 -mx-0.5 -mt-0.5 rounded-t-xl bg-yz-teal-50 p-1 text-center text-sm text-yz-teal-500"
    >
      {{ statusText }}
    </div>
    <AppIcon v-if="icon" :icon="icon" :class="isColumn ? 'mb-2' : 'mr-2'" />
    <span v-else-if="image" :style="`background-image: url(${image})`" class="image" alt=""></span>
    <!--  info: that exclamation mark is important in that case  -->
    <span v-else class="text-[1.25rem]" :class="{ 'mb-2': isColumn, 'mr-2': !isColumn, 'text-[4rem]': isColumn }">
      {{ emoji }}
    </span>
    <div
      class="text"
      :class="{
        'flex grow items-center text-sm': centerText,
        grow: !isColumn,
      }"
    >
      <p
        class="m-0 min-w-20"
        :class="{
          'text-center': isColumn,
        }"
      >
        {{ $t(translationKey) }}
      </p>
      <span v-if="descriptionTranslationKey" class="mb-0 flex text-xs font-normal text-delight-neutral-600">
        {{ $t(descriptionTranslationKey) }}
      </span>
    </div>
    <slot />
  </button>
</template>

<style scoped lang="scss">
// Instead of making those tw I would prefer creating different components for each style

.card-image {
  padding: 0;
  border: 0;
  min-width: 9rem;
  gap: 0;
  flex-grow: 1;
  flex-basis: min-content;

  @media (min-width: 576px) {
    min-width: 15rem;
  }

  .text {
    padding: 1rem;
  }

  .image {
    width: 100%;
    height: 6rem;
    background-size: cover;
    background-position: top center;
    border-radius: 0.75rem 0.75rem 0 0;

    @media (min-width: 576px) {
      height: 8rem;
    }
  }
}
</style>
